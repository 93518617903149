<template>
  <div class="mapItem">
    <router-link :to="{ name: 'CultureMapDetail', query: { id: item.smId } }">
      <div class="clickable">
        <div
          :alt="`${item.title1}${ item.title2 }`"
          class="mapItem__img"
          :style="`background-image: url(${baseURL}${item.image})`"
        ></div>

        <h3 class="mapItem__title">{{ item.title1 }}{{ item.title2 }}</h3>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        image: 'upload/stroll_map/cff3ff071e38.png',
        order: '0',
        play: '探索城市,樂活健身',
        sm_id: '3',
        title1: '開箱礦山記憶-',
        title2: '跨世代的礦山導讀地圖',
        who: ' 三五好友 ,家庭親子 ,銀髮樂齡'
      })
    }
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_WEB_BASE
    }
  }
}
</script>

<style lang="scss">
.mapItem {
  width: 33.33%;
  padding: 1rem;

  &__img {
    padding-bottom: 75%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  &__title {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 500;
    padding-left: 1.6rem;
    margin-top: 1.25rem;
  }
}

@media (max-width: 768px) {
  .mapItem {
    width: 100%;
    padding: 0;
    padding-bottom: 3.5rem;
  }
}
</style>
