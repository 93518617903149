<template>
  <section class="map container">
    <CultureQuestion
      @onChangeFilter="onChangeFilter"
      :currentFilter="currentFilter"
    />

    <div class="map__itemContainer">
      <MapItem
        v-for="mapItem in mapFilterList"
        :key="mapItem.smId"
        :item="mapItem"
      />
    </div>
  </section>
</template>

<script>
import CultureQuestion from '@/components/Culture/CultureQuestion.vue'
import MapItem from '@/components/Culture/Map/MapItem.vue'

// Api
import { apiGetStrollMap } from '@/api/webAPI'

export default {
  components: {
    CultureQuestion,
    MapItem
  },
  data() {
    return {
      mapList: [],
      currentFilter: 'ALL'
    }
  },
  computed: {
    mapFilterList() {
      if (this.currentFilter === 'ALL') return this.mapList
      return this.mapList.filter(
        map =>
          map.who.includes(this.currentFilter) ||
          map.play.includes(this.currentFilter)
      )
    }
  },
  methods: {
    async fetchData() {
      const { filter } = this.$route.query
      this.currentFilter = filter || 'ALL'

      if (this.mapList.length !== 0) return

      const response = (
        await apiGetStrollMap({
          type: 'category'
        })
      ).data.data

      this.mapList = response
    },
    onChangeFilter(filter) {
      this.$router.push({
        name: 'CultureMap',
        query: {
          filter
        }
      })
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.map {
  padding: 6.9rem 0;

  &__itemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>
