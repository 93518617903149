<template>
  <ul class="cultureQuestion__questionUl">
    <a href="#"
      v-for="item in questionList"
      :key="item.answer"
      @click="onClickQuestion(item.answer)"
      class="cultureQuestion__question"
      :class="
        currentFilter === item.answer ? 'cultureQuestion__question--active' : ''
      "
      :style="`background-color: ${item.color}`"
    >
      {{ item.question }}
      <ul class="cultureQuestion__ans">
        <li>{{ item.answer }}</li>
      </ul>
    </a>
  </ul>
</template>

<script>
export default {
  props: {
    currentFilter: {
      type: String,
      default: 'ALL'
    }
  },
  data() {
    return {
      questionList: [
        {
          question: '全部',
          answer: 'ALL',
          color: '#E5E5E0'
        },
        {
          question: '和誰來',
          answer: '三五好友',
          color: '#D7E1CB'
        },
        {
          question: '和誰來',
          answer: '家庭親子',
          color: '#D7E1CB'
        },
        {
          question: '和誰來',
          answer: '銀髮樂齡',
          color: '#D7E1CB'
        },
        {
          question: '玩什麼',
          answer: '探索城市',
          color: '#CCDEDB'
        },
        {
          question: '玩什麼',
          answer: '樂活健身',
          color: '#CCDEDB'
        }
      ]
    }
  },
  methods: {
    onClickQuestion(answer) {
      if (this.currentFilter === answer) return
      this.$emit('onChangeFilter', answer)
    }
  }
}
</script>

<style lang="scss">
.cultureQuestion {
  & ul,
  & li {
    list-style: none;
  }

  &__questionUl {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 6.3rem;
  }

  &__question {
    cursor: pointer;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.7rem;
    text-align: center;
    padding: 0.5rem;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    margin: 1rem;
  }

  &__question:hover,
  &__question--active {
    transform: translateY(-15px);
    transition: transform 0.3s ease-in-out;
  }

  &__ans {
    margin-top: 0.8rem;
  }

  &__ans li {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.6rem;
    text-align: center;
    color: #595757;
    background-color: white;
    width: 11rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .cultureQuestion {
    &__question {
      width: calc(33% - 2rem);
      max-width: 11rem;
    }

    &__ans li {
      width: 100%;
    }
  }
}
</style>
